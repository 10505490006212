import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonPage, IonRow, useIonViewWillEnter, IonFooter, IonSpinner } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import '../css/style.css';
import axios from 'axios';
import { CartContext } from '../../context/CartContext';
import taxRate from '../../config/CommerceSettings';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CheckoutForm from "./CheckoutForm";
import { Capacitor } from '@capacitor/core';

import cross from '../../assets/img/cross.svg';

interface DefaultParams {
    id: string;
    ticketCount: string;
}

const Checkout: React.FC = () => {


    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [billingAddress, setBillingAddress] = useState<string>();
    const [city, setCity] = useState<string>();
    const [postalCode, setPostalCode] = useState<string>();
    const [cardholderName, setCardholderName] = useState<string>();

    const [fieldsChecked, setFieldsChecked] = useState<boolean>();
    const [buttonLoading, setButtonLoading] = useState<boolean>();

    useEffect(() => {
        allFieldsFilled();
    }, [firstName, lastName, billingAddress, city, postalCode, cardholderName]);

    let history = useHistory();

    const goToPreviousPath = () => {
        history.goBack()
    }

    /* Hide Tabbar Start */
    useIonViewWillEnter(() => {
        const tabBar = document.getElementById('app-tab-bar');
        if (tabBar != null) {
            tabBar.style.transition = '0.05s';
            tabBar.style.opacity = '0';

            setTimeout(function () {
                tabBar.style.display = 'none';
            }, 100);
        }
    });
    /* Hide Tabbar End */

    const allFieldsFilled = (() => {
        if ((firstName && lastName && billingAddress && city && postalCode && cardholderName) == undefined || (firstName && lastName && billingAddress && city && postalCode && cardholderName) == null || (firstName && lastName && billingAddress && city && postalCode && cardholderName) == "") {
            setFieldsChecked(false);
        } else {
            setFieldsChecked(true);
        }
    });

    /* Process Payment with CC Processing*/

    const processPayment = (() => {

        setButtonLoading(true);
    });

    const [currentCart, setCart] = useContext(CartContext);

    const subTotalPrice = Number(currentCart.reduce((acc: any, curr: any) => acc + (curr.price * curr.qty), 0));

    const taxTotal = Number((Number(subTotalPrice) * taxRate)).toFixed(2);

    const totalPrice = Number(Number(subTotalPrice) + Number(taxTotal));

    const [clientPub, setClientPub] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [accountId, setAccountId] = useState("");

    const [isOnline, setIsOnline] = useState(false);

    useEffect(() => {
        if (currentCart.length != 0) {
            axios.post(`https://angeliques-api.vervedev.com/api/checkout`, { restaurant_id: 4, tenant_id: process.env.REACT_APP_TENANT_ID, orderSub: (subTotalPrice * 100) })
                .then(res => {

                    if (res.data.online_status == null || res.data.online_status === true) {
                        if (res.data.publishable_key) {
                            setClientPub(res.data.publishable_key);
                            setClientSecret(res.data.client_secret);
                            setAccountId(res.data.account_id);
                            setIsOnline(true);
                        } else {
                            console.error("Publishable key is null in the response.");
                        }
                    } else {
                        alert("Restaurant is offline! Please try again later...")
                    }
                })
                .catch(error => {
                    console.error("Error fetching publishable key:", error);
                });
        }
    }, []);

    let stripePromise = null;

    if (clientPub && currentCart && accountId && Object.keys(currentCart).length > 0) {
        stripePromise = loadStripe(clientPub, {
            stripeAccount: accountId,
        });
        // Proceed with other actions using stripePromise
    } else {
        // Handle the case where clientPub is not set or cart is empty
        console.error('clientPub is not set or cart is empty');
    }

    const appearance = {
        theme: 'stripe',
    };
    const options: any = {
        clientSecret,
        appearance,
    };

    const [isApplePhone, setApplePhone] = useState(false);

    useEffect(() => {
        async function checkIsIOS() {
            const info = Capacitor.getPlatform();
            if (info === 'ios') {
                setApplePhone(true);
            } else {
                setApplePhone(false);
            }
        }

        checkIsIOS();
    }, []);

    return (
        <IonPage>
            <IonContent scrollY={true}>
                <div className={isApplePhone == true ? "ios-wrapper" : "null"}>
                </div>
                <div className="wrapper top-bar fixed-wrapper">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="2">
                                <IonButton onClick={goToPreviousPath} class="back-button">
                                    <img src={cross} className="icon-img" />
                                </IonButton>
                            </IonCol>
                            <IonCol size="8">
                                <h1 className="checkout-title">(<span className="highlight">${totalPrice.toFixed(2)}</span>) Order Total</h1>
                            </IonCol>
                            <IonCol size="2">
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6">
                                <div className="inputs">
                                    <h6>First Name</h6>
                                    <IonInput type="text" onIonChange={(e: { detail: { value: any; }; }) => setFirstName(e.detail.value!)} placeholder="John"></IonInput>
                                </div>
                            </IonCol>
                            <IonCol size="6">
                                <div className="inputs">
                                    <h6>Last Name</h6>
                                    <IonInput onIonChange={(e: { detail: { value: any; }; }) => setLastName(e.detail.value!)} placeholder="Doe" type="text"></IonInput>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <div className="inputs">
                                    <h6>Email</h6>
                                    <IonInput onIonChange={(e: { detail: { value: any; }; }) => setBillingAddress(e.detail.value!)} placeholder="youremail@domain.com" type="email"></IonInput>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6">
                                <div className="inputs">
                                    <h6>City</h6>
                                    <IonInput onIonChange={(e: { detail: { value: any; }; }) => setCity(e.detail.value!)} placeholder="Toronto" type="text"></IonInput>
                                </div>
                            </IonCol>
                            <IonCol size="6">
                                <div className="inputs">
                                    <h6>Postal Code</h6>
                                    <IonInput onIonChange={(e: { detail: { value: any; }; }) => setPostalCode(e.detail.value!)} placeholder="Z1Z Z1Z" type="text"></IonInput>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <div className="inputs">
                                    <h6>Country</h6>
                                    <IonInput value="Canada" readonly></IonInput>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <div className="inputs">
                                    <h6>Cardholder Name</h6>
                                    <IonInput onIonChange={(e: { detail: { value: any; }; }) => setCardholderName(e.detail.value!)} placeholder="John Doe" type="text"></IonInput>
                                </div>
                                <br>
                                </br>
                                <br></br>
                                {clientSecret && (
                                    <Elements options={options} stripe={stripePromise}>
                                        <CheckoutForm secretKey={clientSecret} full_name={cardholderName} customer_email={billingAddress} currentCart={currentCart} totalPrice={totalPrice} buttonLoading={buttonLoading} setButtonLoading={setButtonLoading}/>
                                    </Elements>
                                )}
                                <br></br>
                                <br></br>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
                <div className="checkout-footer">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <p>You agree to be charged on the card entered above.</p>
                                {isOnline == true ?
                                    (fieldsChecked === true ?
                                        <IonButton className="action-button highlight-button" id="complete-order-button" form='payment-form' type="submit" onClick={() => processPayment()}>
                                            {buttonLoading == true ? <IonSpinner name="circular"></IonSpinner> : "Process Order"}
                                        </IonButton>
                                        :
                                        <IonButton className="action-button dark-highlight-button">All Fields Not Filled</IonButton>)
                                    :
                                    <IonButton className="action-button dark-highlight-button">Restaurant Offline</IonButton>}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Checkout;
