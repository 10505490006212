import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IonContent, IonPage, IonGrid, IonRow, IonCol, useIonViewWillEnter, IonButton, IonIcon } from '@ionic/react';
import GoogleMaps from '../utils/GoogleMaps';
import Confetti from 'react-confetti';
import { CartContext } from '../../context/CartContext';
import { Capacitor } from '@capacitor/core';

import cross from '../../assets/img/cross.svg';

interface LocationState {
    jsonParam: any; // Adjust 'any' to the actual type of your jsonParam
}

const OrderConfirmation: React.FC = () => {

    const [numConfetti, setNumConfetti] = useState(500); // Initial number of confetti pieces
    const [showConfetti, setShowConfetti] = useState(true);

    const [currentCart, setCart] = useContext(CartContext);

    useIonViewWillEnter(() => {
        setCart([]);
        const tabBar = document.getElementById('app-tab-bar');
        if (tabBar != null) {
            tabBar.style.opacity = '0';
            tabBar.style.display = 'none';
        }
    });
    /* Hide Tabbar End */

    useEffect(() => {
        // Function to start confetti animation
        const startConfettiAnimation = () => {
            // Gradually decrease the number of confetti pieces over time
            const interval = setInterval(() => {
                setNumConfetti((prevNum) => prevNum - 100); // Decrease by 100 pieces in each interval
                if (numConfetti <= 0) {
                    clearInterval(interval); // Stop the interval when number of confetti pieces reaches zero
                    // Hide confetti after a short delay
                    setTimeout(() => {
                        setShowConfetti(false);
                    }, 2000); // Adjust delay as needed
                }
            }, 300); // Adjust interval duration as needed

            // Clean up interval on component unmount
            return () => clearInterval(interval);
        };

        // Execute the confetti animation function after the page has fully loaded
        window.onload = startConfettiAnimation;

        // Ensure the confetti animation starts immediately if the page is already loaded
        if (document.readyState === 'complete') {
            startConfettiAnimation();
        }
    }, [numConfetti]); // Dependency array remains unchanged



    const location = useLocation();
    const { jsonParam } = location.state as LocationState || {};

    const [isApplePhone, setApplePhone] = useState(false);

    useEffect(() => {
        async function checkIsIOS() {
            const info = Capacitor.getPlatform();
            if (info === 'ios') {
                setApplePhone(true);
            } else {
                setApplePhone(false);
            }
        }

        checkIsIOS();
    }, []);

    return (
        <IonPage>
            <IonContent scrollY={true}>
                <div className={isApplePhone == true ? "ios-wrapper" : "null"}>
                </div>
                <div className="wrapper top-bar">
                    <Confetti numberOfPieces={numConfetti} />
                    <IonGrid class="ion-no-padding">
                        <IonRow class="ion-no-padding">
                            <IonCol size="3" class="ion-no-padding">
                                <IonCol size="12" class="left-align ion-no-padding">
                                    <IonButton routerLink="/tab1" routerDirection="back" class="back-button">
                                    <img src={cross} className="icon-img" />
                                    </IonButton>
                                </IonCol>
                            </IonCol>
                            <IonCol size="6" class="center-align ion-no-padding">
                                <h1 className="page-title">Order Confirmed</h1>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid className="no-padding">
                        <IonRow className="no-padding">
                            <IonCol size="12" className="no-padding">
                                <br></br>
                                <GoogleMaps />
                                <div className="text-center">
                                    <img src="https://orderwithease.ca/images/pita-delites/pita-delites-logo.png" alt="logo" width="200" />
                                    <p>Thank you for your order at Pita Delites!</p>
                                </div>
                                <br></br>
                                <h3>Order Details</h3>
                                {jsonParam && jsonParam.map((cartItems: { name: string; qty: number; id: number; price: number; category: string; options: any; choices: any; }, idx: string | number | null | undefined) => (
                                    <div className="cart-item" key={idx}>
                                        <IonGrid class="ion-no-padding">
                                            <IonRow class="ion-no-padding">
                                                <IonCol size="12" class="ion-no-padding">
                                                    <h4 className="cart-item-name">{cartItems.name}</h4>
                                                    {/* <h5 className="cart-item-category">{cartItems.category}</h5> */}
                                                    <ul className="cart-item-options">
                                                        {cartItems.choices.map((choice: { name: string; price: number }, optionIdx: string | number) => (
                                                            <div key={optionIdx} className="cart-item-option">
                                                                <li>{choice.name} (+${choice.price})</li>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                    <ul className="cart-item-options">
                                                        {cartItems.options.map((option: { name: string; price: number }, optionIdx: string | number) => (
                                                            <div key={optionIdx} className="cart-item-option">
                                                                <li>{option.name} (+${option.price})</li>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                    <IonRow class="ion-no-padding">
                                                        <IonCol size="8" class="ion-no-padding">
                                                            <h6 className="cart-item-price"><span className="dollar-sign">$</span>{cartItems.price}</h6>
                                                        </IonCol>
                                                        <IonCol size="4" class="ion-no-padding">
                                                            <IonRow class="ion-no-padding">
                                                                <IonCol size="12" class="ion-no-padding">
                                                                    <h6 className="cart-item-qty">x{cartItems.qty}</h6>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                ))}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default OrderConfirmation;
